import SentryInit from './sentry.init'

import { state } from './store/state'
import { isTest } from './who-am-i'
import { PartialDirectorPort, initialize } from './business-logic/director'
import { wrap } from './store/notifications'
import { info } from './logger'
import { manualSpan } from './tracer'
import { globalPool } from './business-logic/pool-aggregator'

SentryInit()

if (!isTest()) {
  const setupWindowObjects = () => {
    state.setGlobal('pool', globalPool)
  }

  const fn = () => {
    info('Application started')
    state.setPorts(new PartialDirectorPort())
    initialize()

    setupWindowObjects()
  }

  manualSpan({ name: 'boot' }, (span) => {
    const unsubscribe = state.uiFullyMounted.listen(() => {
      unsubscribe()
      span.end()
    })

    wrap(fn, state)()
  })
}
